/*******************************************************************************
 * Themes
 ******************************************************************************/
 
:root {
  --global-bg-color: #{$white-color};
  --global-code-bg-color: #{$code-bg-color-light};
  --global-text-color: #{$black-color};
  --global-text-color-light: #{$grey-color};
  --global-theme-color: #{$purple-color};
  --global-hover-color: #{$purple-color};
  --global-footer-bg-color: #{$grey-color-dark};
  --global-footer-text-color: #{$grey-color-light};
  --global-footer-link-color: #{$white-color};
  --global-distill-app-color: #{$grey-color};
  --global-divider-color: rgba(0,0,0,.3);

  .fa-sun {
    display : none;
  }
  .fa-moon {
    padding-left: 10px;
    padding-top: 12px;
    display : block;
  }
}

html[data-theme='dark'] {
  --global-bg-color: #{$grey-color-dark};
  --global-code-bg-color: #{$code-bg-color-dark};
  --global-text-color: #{$grey-color-light};
  --global-text-color-light: #{$grey-color-light};
  --global-theme-color: #{$cyan-color};
  --global-hover-color: #{$cyan-color};
  --global-footer-bg-color: #{$grey-color-light};
  --global-footer-text-color: #{$grey-color-dark};
  --global-footer-link-color: #{$black-color};
  --global-distill-app-color: #{$grey-color-light};
  --global-divider-color: rgba(255, 255, 255, 0.3);

  .fa-sun {
    padding-left: 10px;
    padding-top: 12px;
    display : block;
  }
  .fa-moon {
    display : none;
  }
}
